import * as React from "react"

const NotFoundPage = () => {
  return (
    <main>
      <p>Not found!</p>
    </main>
  )
}

export default NotFoundPage
